@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.salesGlobal {
  font-family: "Inter", sans-serif;
  position: relative;
}

@media (min-width: 992px) {
  .salesNavbar {
    padding: 17px 30px 10px 30px;
    z-index: 1000 !important;
  }
}

.salesNavbar p {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
}

.salesNavbar span {
  font-family: "Inter", sans-serif !important;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
