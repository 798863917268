.card {
    margin-bottom: 16px;
    padding: 16px 18px;
}

.header {
    display: flex;
    margin-bottom: 44px;
}

.trackImage {
    width: 91px;
    height: 91px;
    margin-right: 23px;
}

.openingCard {
    margin-bottom: 13px;
    width: 100%;
}

.seats {
    color: #4CCD59;
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.seats svg path {
    fill: #4CCD59;
    margin-right: 5px;
}

.trackName {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}

.level {
    font-size: 12px;
    font-weight: 600;
    color: #B9BDC1;
}