.trackCardContainer {
  margin-bottom: 24px;
  border: 1px solid #ebecf0;
  border-radius: 8px 8px 8px 8px;
}
.trackCard {
  height: 111px;

  background: #ffffff;

  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-between;
}
.trackCardImage {
  width: 87px;
  height: 87px;

  border-radius: 8px;
}
.leftSection {
  max-width: 43%;
  display: flex;
  align-items: end;
}
.trackNameContainer {
  margin-left: 24px;
  height: 87px;
}
.trackName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  margin-top: 5px;

  color: #000000;
}
.productScheduleContainer {
  display: flex;
}

.productScheduleTag {
  width: 49px;
  height: 29px;

  border-radius: 20px;
  margin-right: 8px;
}
.rightSection {
  display: flex;
  align-items: flex-end;
}

.tableRowLight {
  background-color: #ffffff;
}
.tableRowDark {
  background-color: #fafafa;
}

.titleElement {
  padding: 11px 14px;
  display: block;
  font-weight: 600;
  font-size: 18px;
  color: #333333;

}

.contentElement {
  margin: 0px;
  display: block;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  color: #828A92;
}
