@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.dashboard {
  padding: 28px 57px;
  background-color: #FCFCFC;
  height: calc(100vh - 67px);
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #333333;
  margin-bottom: 76px;
}
.filtersContainer {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
  justify-content: space-between;
}
.salesFilter {
  width: 426px;
  height: 50px;
}

.productScheduleTag {
  border-radius: 20px;
}
.selectInputContainer {
  
  z-index: 0;
}

.userCard {
  background-color: transparent;
}

@media (max-width: 991px) {
  .dashboard {
    padding: 28px 23px;
    height: calc(100vh - 72px);
  }
  .title {
    margin-bottom: 17px;
  }
}


