.ant-select-selector {
  width: 100% !important;
  height: 50px;
  background: #ffffff;

  border: 1px solid #ebecf0;
  border-radius: 10px;
}

.Filters_container__ogkjU {
  width: 100%;
  height: 50px;
}
.Filters_container__ogkjU div {
  width: 100%;
}
.ant-space, .ant-space-item,.ant-select, .ant-select-selector  {
  width: 100% !important;
}
@media(max-width: 576px) {
  .Filters_container__ogkjU {
    width: 100%;
    margin: auto;
  }
  .Filters_container__ogkjU div {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .Filters_container__ogkjU {
    width: 426px;
  }
  .Filters_container__ogkjU div {
    width: 426px;
  }
}

.UserCard_userCard__9K-BW {
  width: 220px;
}
thead {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #828a92;
}
