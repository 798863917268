
  .modalTitle{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 20px;
color: #828A92;
  }
  .rowContainer{
    display:flex;
    flex-direction: row;
    gap:10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .trackStartDateStyles{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 150%;
color: #0F0F0F;
margin-top: 10px;
  }
  .dividerStyles{
    border-bottom: 1px solid #EBECF0;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .toastStyles{
    margin-top: 10px;
  }
  .spaceBetweenContainer{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:  15px;
  }
  .startDateText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #828A92;
  }
  .startDateValue{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
color: #333333;
  }
  .userCardStyles{
    margin-top: 25px;
  }