.container {
    max-width: 407px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: auto;
    margin-top: 70px;
}

.svg {
 margin-bottom: 40px;
}

.svg path {
    fill: #000000
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #828A92;
}

.description {
    font-size: 12px;
    font-weight: 500;
    color: #828A92;
}